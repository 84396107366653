@use '@angular/material' as mat;
@import "./typography";
@import "./colors";
@import "./layouts";
@import "./checkbox";

$font-size-xs: 12px;
$font-size-s: 14px;
$font-size: 16px;
$font-size-l: 18px;

$font-size-big-s: 28px;
$font-size-big-m: 32px;
$font-size-big: 36px;
$font-size-big-l: 40px;
$button-font-size-m: 12px;
$button-font-size-s: 11px;
$button-font-size-xs: 10px;

$background-color: #f9f9f9;
$button-color: #ff671f;
$button-hover-color: #ff671f;
$button-font-color: #fff;

$main-panel-side-padding: 155px;
$pictureAndborderWidth: Calc(
  #{$master-header-picture-width} + #{$master-header-picture-border}
);

.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f6f6f6;
}

.mat-mdc-form-field.s-size-field {
  width: $field-width-s;
}
.mat-mdc-form-field.m-size-field {
  width: $field-width-m;
}
.mat-mdc-form-field.lg-size-field {
  width: $field-width-l;
}

//Override Angular Material css
.mat-mdc-form-field .mat-form-field-label {
  font: $lengend-font;
}

.mat-mdc-form-field {
  font: $p-font;
  margin-right: 4rem;
  max-width: 100%;
}

.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: $color-orange;
  background-clip: border-box;
}

.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: $color-orange;
}

.mat-mdc-form-field .mat-form-field-flex {
  border-bottom: 0.2rem solid rgba(34, 34, 34, 0.5);
  position: relative;
}

.mat-mdc-form-field.mat-focused .mat-form-field-flex {
  border-bottom: 0.2rem solid $color-orange;
}

.mat-mdc-form-field .mat-form-field-flex::before {
  content: "";
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  height: 0.8rem;
  width: 0.2rem;
  border-left: 0.2rem solid rgba(34, 34, 34, 0.5);
}

.mat-mdc-form-field.mat-focused .mat-form-field-flex::before {
  border-left: 0.2rem solid $color-orange;
}

.mat-mdc-form-field .mat-form-field-flex::after {
  content: "";
  position: absolute;
  bottom: -0.2rem;
  right: -0.1rem;
  height: 0.8rem;
  width: 0.2rem;
  border-right: 0.2rem solid rgba(34, 34, 34, 0.5);
}

.mat-mdc-form-field.mat-focused .mat-form-field-flex::after {
  border-right: 0.2rem solid $color-orange;
}

.custom-checkbox{
  mat-checkbox{
    .mat-mdc-checkbox-label{
      color:$color-grey;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  width: 18px !important;
  height: 18px !important;
}

snack-bar-container {
  background-color:transparent !important;
  box-shadow: none !important;
  color: #312626 !important;
  max-width: 100% !important;
}

.mat-mdc-snack-bar-container {
  max-width: 100% !important;
}

.mat-mdc-snack-bar-label {
 padding:0!important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  background-color:#fff !important;
}

mat-error {
  display: inline-block;
  margin-right: 0;
}
mat-error:not(:first-child):before {
  content: " et ";
  white-space: pre;
}

/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container {
  overflow: visible !important;
  border-radius: 0.8rem !important;
}

.mat-datepicker-toggle.mat-datepicker-toggle-active {
  color: $color-orange !important;
}

.mat-calendar .mat-calendar-body-selected {
  background-color: $color-orange !important;
}

mat-expansion-panel {
  box-shadow: $box-shadow !important;

  mat-expansion-panel-header {
    padding: 3.2rem 4.8rem;
    height: 100% !important;
    mat-panel-title {
      color: $color-orange;
      font-size: 22px;
      display: inline-block;
      height: 40px;
      line-height: 100%;
      vertical-align: bottom;
    }
  }

  .mat-expansion-panel-content {
    //font: inherit !important;
    font: $p-font;
    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

.popup-panel {
  padding: 1.6rem 2.6rem 1.6rem 2.6rem;
}

.mat-mdc-dialog-title {
  font: $h1-font;
  text-align: center;
}

.mat-mdc-dialog-content {
  opacity: 0.6;
}

.mat-mdc-dialog-actions {
  width: 100%;
  margin-top: 6rem;
  justify-content: flex-end;

  .accept-btn {
    color: $color-orange;
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option.mat-mdc-selected {
    background-color: $color-orange !important;
    color: white !important;
  }
}

.divider {
  background-color: rgba(34, 34, 34, 0.12);
  height: 1px;
  margin: 15px;
}

.block-with-text {
  font-size: $font-size;
  display: block;
  overflow: hidden;
  position: relative;
  line-height: 1em;
  max-height: 3em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.block-with-text:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}

/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.custom-button {
  border-radius: 20px;
  background-color: $button-color;
  color: $button-font-color;
  padding: 3px 25px;
  font-size: $button-font-size;
}

.custom-button.flat {
  background-color: transparent;
  color: $font-color;
}

.list-style {
  display: block;
  list-style: none;
  padding: 0;
  margin: 5px 0;
  overflow: hidden;
  .list-header {
    font-weight: $font-weight;
    color: $color-grey;
    text-transform: uppercase;
    font-size: $font-size;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: $font-size;
    font-weight: $font-weight-light;
    margin-right: 5px;
  }
}

.list-style.inline {
  .list-header {
    margin: 1px 10px 0 0;
    float: left;
    min-width: 65px;
  }

  li {
    float: left;
  }
}

.box-shadow {
  box-shadow: $box-shadow;
}

.page-header {
  width: auto;
  margin: 5rem 1.5rem;
  display: block;
  position: relative;
  white-space: nowrap;
  margin-top: $page-header-margin-top;
}

.error-field {
  margin-top: -1.2rem;
  font: $lengend-font;
  color: red;
}

//Medium
@media screen and (min-width: 993px) and (max-width: 1200px) {
  $pictureAndborderWidth: Calc(
    #{$master-header-picture-width-m} + #{$master-header-picture-border-m}
  );
  .custom-button {
    font-size: $button-font-size-m;
    padding: 3px 15px;
  }
  .page-header {
    width: auto;
    height: $page-header-height;
    //padding-left: $pictureAndborderWidth;
    display: block;
    position: relative;
    white-space: nowrap;
    margin-top: $page-header-margin-top;
  }
}
//Small
@media screen and (min-width: 769px) and (max-width: 992px) {
  $pictureAndborderWidth: Calc(
    #{$master-header-picture-width-s} + #{$master-header-picture-border-s}
  );
  .custom-button {
    font-size: $button-font-size-s;
    padding: 0px 10px;
  }
  .page-header {
    width: auto;
    height: $page-header-height;
    //padding-left: $pictureAndborderWidth;
    display: block;
    position: relative;
    white-space: nowrap;
    margin-top: $page-header-margin-top;
  }
}
//XSmall
@media screen and (max-width: 768px) {
  $pictureAndborderWidth: Calc(
    #{$master-header-picture-width-xs} + #{$master-header-picture-border-xs}
  );
  .custom-button {
    font-size: $button-font-size-xs;
    padding: 0px 10px;
  }
  .page-header {
    width: auto;
    height: 10em;
    padding: 0.5em 0.5em 0.5em 0.5em;
    display: block;
    position: relative;
    white-space: nowrap;
    margin-top: 0;
    line-height: 2.5em;
    background-color: #f5f5f5;
  }
}

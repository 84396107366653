.mat-mdc-checkbox{

  --mdc-checkbox-selected-focus-state-layer-opacity: 0;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;

  --mdc-checkbox-unselected-icon-color: rgba(34, 34, 34, 0.5) !important;
  --mdc-checkbox-unselected-hover-icon-color: #ff671f !important;
  --mdc-checkbox-unselected-focus-icon-color: #ff671f !important;  
  --mdc-checkbox-unselected-hover-state-layer-color: #ff671f !important;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ff671f !important;

  --mdc-checkbox-selected-icon-color: #ff671f !important;
  --mdc-checkbox-selected-hover-icon-color: #ff671f !important;
  --mdc-checkbox-selected-checkmark-color: white !important;  
  --mdc-checkbox-selected-focus-icon-color: #ff671f !important;  
  --mdc-checkbox-selected-hover-state-layer-color: #ff671f !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff671f !important;

}
$font-family: Roboto;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$h1-font-size: 4.8rem;
$h1-font: $font-weight-light $h1-font-size $font-family;

$h2-font-size: 3.6rem;
$h2-font: $font-weight-light $h2-font-size $font-family;

$h3-font-size: 2.2rem;
$h3-font: $font-weight-regular $h3-font-size $font-family;

$font-size-xs: 12px;
$font-size-s: 14px;
$font-size: 16px;
$font-size-l: 18px;
$font-size-big-s: 28px;
$font-size-big-m: 32px;
$font-size-big: 36px;
$font-size-big-l: 40px;

$btn-alone-font-size: 1.8rem;
$btn-alone-letter-spacing: 0.05rem;
$btn-alone-font: $font-weight-bold $btn-alone-font-size $font-family;

$button-font-size: 1.4rem;
$button-letter-spacing: 0.1rem;
$button-font: $font-weight-bold $button-font-size $font-family;

$button-font-size-m: 12px;
$button-font-size-s: 11px;
$button-font-size-xs: 10px;

$p-font-size: 1.7rem;
$p-menu-font: $font-weight-bold $p-font-size $font-family;
$p-font: $font-weight-regular $p-font-size $font-family;

$legend-font-size: 1.3rem;
$lengend-font: $font-weight-regular $legend-font-size $font-family;

$font-weight: normal;
$font-weight-light: 300;
$font-color: #222;

@import "./styles/common";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "../node_modules/@smag-cloud/smag-components/assets/smag_theme.scss";

:root, *{
  box-sizing:border-box;
}

html {
  background-color: $background-color;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 10px;
}

body {
  font-family: $font-family;
  font-weight: $font-weight;
  color: $font-color;
  width: $body-width;
  max-width: $body-max-width;
  background-color: $background-color;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
}

section {
  display: block;
  margin: $section-margin-vertical $section-margin-horizontal;
  padding: $section-padding;
  background-color: #f6f6f6;
  border-radius: 8px;
}

//@import "./colors";

$master-header-height: 30rem;
$master-header-height-m: 30rem;
$master-header-height-s: 30rem;
$master-header-height-xs: 250px;

$master-header-picture-height: 220px;
$master-header-picture-width: 220px;
$master-header-picture-border: 10px;
$master-header-picture-height-m: 200px;
$master-header-picture-width-m: 200px;
$master-header-picture-border-m: 10px;
$master-header-picture-height-s: 180px;
$master-header-picture-width-s: 180px;
$master-header-picture-border-s: 8px;
$master-header-picture-height-xs: 120px;
$master-header-picture-width-xs: 120px;
$master-header-picture-border-xs: 6px;

$section-padding: 1.5em;
$section-margin-vertical: 1.5em;
$section-margin-horizontal: 0.75em;
$section-height: 45vh;

$page-header-height: 120px;
$page-header-margin-top: 25px;

$body-width: 100%;
$body-max-width: 100%;

$field-width-s: 30rem;
$field-width-m: 50rem;
$field-width-l: 90rem;

$icon-size: 3rem;

$box-shadow: 0 0.8rem 1.2rem rgba(2, 2, 2, 0.04);
